/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/vue@2.7.16/dist/vue.js
 * - /npm/vue-router@3.1.3/dist/vue-router.min.js
 * - /npm/vue-multiselect@2.1.6/dist/vue-multiselect.min.js
 * - /npm/axios@0.19.0/dist/axios.min.js
 * - /npm/js-cookie@2.2.1/src/js.cookie.min.js
 * - /npm/noty@3.1.4/lib/noty.min.js
 * - /npm/vue-snotify@3.2.1/vue-snotify.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
